/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* global $ */
import React, { Component } from 'react';
import Toolbar from '../../Components/Toolbar';
import LateralButton from '../../../Components/Generic/Buttons/LateralButton/Index';

import './Index.scss';

const removeDiacritics = str => str
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '');

const normalizedContains = (phrase, term) => {
  const regExp = new RegExp(removeDiacritics(term), 'gi');
  return regExp.test(removeDiacritics(phrase));
};

class ImpuestosIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagosPersonas: props.model.personas,
      pagosEmpresas: props.model.empresas,
      busqueda: '',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = this.search.bind(this);
    this.handleSearchKeyUp = this.handleSearchKeyUp.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    const { model } = this.props;
    const expressPayment = $.Event('addbuttonevent', {
      btn: <LateralButton className="LateralButton--gold" icon="fas fa-car fa-lg" title="PAGO EXPRESS" link={model.expressPaymentUrl} target="_blank" />,
    });
    $(window).trigger(expressPayment);
  }

  handleSearchKeyUp(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.search();
    }
  }

  handleSearchChange(event) {
    this.setState({
      busqueda: event.target.value,
    });
  }

  search() {
    const { busqueda } = this.state;
    const { model } = this.props;
    if (busqueda) {
      this.setState({
        pagosPersonas: model.personas.filter(pago => normalizedContains(pago.Name, busqueda)),
        pagosEmpresas: model.empresas.filter(pago => normalizedContains(pago.Name, busqueda)),
      });
    } else {
      this.reset();
    }
  }

  reset() {
    const { model } = this.props;
    this.setState({
      pagosPersonas: model.personas,
      pagosEmpresas: model.empresas,
    });
  }

  render() {
    const { pagosPersonas, pagosEmpresas, busqueda } = this.state;
    const { model } = this.props;
    return (
      <>
        <Toolbar />
        <div className="container mb-10">
          <div className="w-100 w-sm-80 m-auto">
            <div className="row my-5">

              <div className="col-sm-12">

                <h5 className="mb-3 text-primary">TRÁMITES</h5>
                <div className="d-flex flex-column flex-lg-row mb-5">
                  <div className="flex-fill me-lg-4">
                    <div className="input-group input-group-lg input-group-pill">
                      <input
                        type="input"
                        placeholder="¿Qué servicio o trámite buscas?"
                        name="search"
                        className="form-control"
                        value={busqueda}
                        onChange={this.handleSearchChange}
                        onKeyUp={this.handleSearchKeyUp}
                      />
                      <button type="button" name="btn-search" className="btn btn-primary" onClick={this.search}>
                        <span className="mdi mdi-magnify me-2" />Buscar
                      </button>
                    </div>
                  </div>
                  <div className="text-end">
                    <button type="button" name="btn-search" className="btn btn-secondary btn-lg me-2" onClick={this.reset}>
                      <span className="mdi mdi-reload me-2" />Restablecer
                    </button>
                    <a className="btn btn-warning btn-lg" href={model.urlPagoFolio}>
                      Pago por Pase a Caja
                    </a>
                  </div>
                </div>

                <ul className="nav justify-content-center mb-5" id="impuestos-tabs" role="tablist">
                  <li className="nav-item me-2" role="presentation">
                    <a className="nav-link h4 mb-0 fw-700 py-1 border-2 border-dark active" id="personas-tab" data-bs-toggle="tab" data-bs-target="#personas-tab-pane" type="button" role="tab" aria-controls="personas-tab-pane" aria-selected="true">PERSONA</a>
                  </li>
                  <li className="nav-item ms-2" role="presentation">
                    <a className="nav-link h4 mb-0 fw-700 py-1 border-2 border-dark" id="empresas-tab" data-bs-toggle="tab" data-bs-target="#empresas-tab-pane" type="button" role="tab" aria-controls="empresas-tab-pane" aria-selected="false">EMPRESA</a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="personas-tab-pane" role="tabpanel" aria-labelledby="personas-tab" tabIndex="0">
                    <div className="row g-4">
                      {
                        pagosPersonas.map((item, index) => (
                          <div className="col-6 col-lg-4 col-xxl-3" key={`impuesto-persona-${index}`}>
                            <div className="card card-body d-flex flex-column align-items-center p-md-2 pt-30px justify-content-between impuesto-card">
                              <div className="impuesto-icon-wrap d-flex align-items-center py-1">
                                <img src={item.Image} alt="Imagen" loading="lazy" />
                              </div>
                              <h3 className="text-primary fw-700 mb-0 fs-5 text-center text-uppercase">{item.Name}</h3>
                              <a href={item.Url} className="btn btn-primary fw-500">
                                <img src="/assets/v3/images/logo-llave-sonora-icono.svg" alt="Icono" className="align-bottom icono-btn-tramite me-1" />
                                Iniciar trámite
                              </a>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="tab-pane fade" id="empresas-tab-pane" role="tabpanel" aria-labelledby="empresas-tab" tabIndex="0">
                    <div className="row g-4">
                      {
                        pagosEmpresas.map((item, index) => (
                          <div className="col-6 col-lg-4 col-xxl-3" key={`impuesto-empresa-${index}`}>
                            <div className="card card-body d-flex flex-column align-items-center p-md-2 pt-30px justify-content-between impuesto-card">
                              <div className="impuesto-icon-wrap d-flex align-items-center py-1">
                                <img src={item.Image} alt="Imagen" loading="lazy" />
                              </div>
                              <h3 className="text-primary fw-700 mb-0 fs-5 text-center text-uppercase">{item.Name}</h3>
                              <a href={item.Url} className="btn btn-primary fw-500">
                                <img src="/assets/v3/images/logo-llave-sonora-icono.svg" alt="Icono" className="align-bottom icono-btn-tramite me-1" />
                                Iniciar trámite
                              </a>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ImpuestosIndex.defaultProps = {
  model: {
    urlHacienda: 'https://hacienda.sonora.gob.mx/',
    urlPagosPersonas: 'https://hacienda.sonora.gob.mx/personas',
    urlPagosEmpresas: 'https://hacienda.sonora.gob.mx/empresas',
    urlPagoFolio: 'https://cuentaunica.siiafhacienda.gob.mx/Pase/PagoPorFolio',
    urlRevalidacion: 'https://cuentaunica.siiafhacienda.gob.mx/ExpressVehicular/Verificacion',
    urlSeguimiento: 'https://cuentaunica.siiafhacienda.gob.mx/Guia/Seguimiento',
    urlRO: 'https://cuentaunica.siiafhacienda.gob.mx/ReciboOficial/Busqueda',
    urlFacturacion: 'https://facturacion.siiafhacienda.gob.mx/',
    urlValidacionLicencia: 'https://verificalicenciadeconducir.sonora.gob.mx/Estatus/Captura',
    urlValidacionActas: 'https://registrocivil.sonora.gob.mx/validacion',
  },
};

export default ImpuestosIndex;
